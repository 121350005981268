#skills{
    background-color: #f5f5f5;
    padding: 35px 30px;
}
#skills h4{
    color: #000;
}
.line-text h4 {
    font-size: 22px;
    position: relative;
    left: 110px;
    flex-basis: inherit;
    margin: 0px;
    width: -moz-fit-content;
    width: fit-content;
}
.skill-tech, .tech{
    width: 100%;
    padding: 15px 30px;
}
.tech{
    display: inline-flex;
}
.tech-icon i{
    font-size: 30px;
}
.tech-icon .devicon-javascript-plain.colored{
    color: #deca48;
}
@media (max-width: 1024px){
    #skills{
        padding: 35px;
    }
}

@media (max-width: 991px){
    #skills{
        padding: 35px;
    }
}